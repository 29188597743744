import { Container, Stack, Typography } from "@mui/material";
//import { Link } from "react-router-dom";
import Page from "src/components/Page";
import Hero from "./Hero";
import AnimatedHeaderUnderline from "src/components/AnimatedHeaderUnderline";
import IconWithDescription from "./IconWithDescription";
import CreateObituaryButton from "../../components/CreateObituaryButton";

export default function Landing() {
  return (
    <Page
      title="The Digital Gravesite Experience"
      meta={{
        description:
          "Looking for a way to keep your loved ones alive long after they're gone? Our custom QR code grave marker plaques bring their stories to life. Discover how.",
      }}
    >
      <Hero
        headline={<>Personalized Sympathy Gifts</>}
        subtext={
          "Give something that lasts longer than a gift basket. Personalized tributes are the perfect bereavement gift."
        }
        imageWithoutExtension="/static/homepage/hero-alt"
        buttonStack={
          <Stack direction="row" spacing={2}>
            <CreateObituaryButton variant="contained" color="primary">
              Create Tribute
            </CreateObituaryButton>
          </Stack>
        }
      />
      <Container sx={{ mt: 1, paddingBottom: 6 }}>
        <Stack>
          <Typography
            variant="h2"
            sx={{ textAlign: "center", margin: "0 auto" }}
          >
            How It Works
            <AnimatedHeaderUnderline />
          </Typography>
        </Stack>
        <Stack
          sx={{
            marginTop: 1,
          }}
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-evenly"
        >
          <IconWithDescription
            title="Upload Content"
            description="You upload photos, fill out the questionnaire, and submit your loved one's life story."
            iconUrl="/static/homepage/edit-icon.svg"
            buttonComponent={
              <CreateObituaryButton variant="outlined">
                Get Started
              </CreateObituaryButton>
            }
          />
          <IconWithDescription
            title="Select QR Code Plaque"
            description="You pick the style of your weather proof plaque. We laser engrave a QR code
                           for people to find info about your loved one."
            iconUrl="/static/homepage/gravestone-icon.svg"
            imageWithoutExtension="/static/homepage/example-plaques"
          />
          <IconWithDescription
            title="Place Plaque at Gravesite"
            description="Place the QR Plaque at the gravesite, urn or other memorial for visitors to scan and
                          see your loved one's life story."
            iconUrl="/static/homepage/qr-code-icon.svg"
            imageWithoutExtension="/static/homepage/celebratelifeco-on-phone"
          />
        </Stack>
      </Container>
    </Page>
  );
}

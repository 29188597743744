import React, { useEffect } from "react";
import { Outlet, useLocation, useRoutes } from "react-router-dom";

import AuthenticationLayout from "./layouts/AuthenticationLayout";

// non lazy loaded pages
import PrivacyPolicy from "./pages/PrivacyPolicy";
import NotFound from "./pages/Page404";
import { Suspense } from "react";
import Spinner from "./components/Spinner";
import Redirector from "./pages/Redirector";
import AuthenticatedOutlet from "./layouts/AuthenticatedOutlet";
import Landing from "./pages/landing/Landing";
import { useAppState } from "./hooks/appState";

// lazy loaded components for code splitting based on routes
const PortalLayout = React.lazy(() => import("./layouts/portal"));
const Login = React.lazy(() => import("./pages/login/Login"));
const Register = React.lazy(() => import("./pages/register/Register"));
const Verify = React.lazy(() => import("./pages/Verify"));
const VerifyNewEmail = React.lazy(() => import("./pages/VerifyNewEmail"));
const AcceptInvite = React.lazy(() => import("./pages/AcceptInvite"));
const ForgotPassword = React.lazy(() =>
  import("./pages/forgotPassword/ForgotPassword")
);
const PassswordReset = React.lazy(() => import("./pages/PasswordReset"));

const Product = React.lazy(() => import("./pages/products/Product"));
const ProductList = React.lazy(() => import("./pages/products/ProductList"));
const EditProduct = React.lazy(() => import("./pages/products/EditProduct"));

const Home = React.lazy(() => import("./pages/landing/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const About = React.lazy(() => import("./pages/About"));

const AdminArticle = React.lazy(() => import("./pages/articles/AdminArticle"));
const AdminArticleList = React.lazy(() =>
  import("./pages/articles/AdminArticleList")
);
const EditArticle = React.lazy(() => import("./pages/articles/EditArticle"));

const UserArticleList = React.lazy(() =>
  import("./pages/articles/UserArticleList")
);
const UserArticle = React.lazy(() => import("./pages/articles/UserArticle"));
const TopicArticleList = React.lazy(() =>
  import("./pages/articles/TopicArticleList")
);

const Topic = React.lazy(() => import("./pages/topics/Topic"));
const TopicList = React.lazy(() => import("./pages/topics/TopicList"));
const EditTopic = React.lazy(() => import("./pages/topics/EditTopic"));

const ObituaryList = React.lazy(() =>
  import("./pages/obituaries/ObituaryList")
);
const Obituary = React.lazy(() => import("./pages/obituaries/Obituary"));
const CreateObituary = React.lazy(() =>
  import("./pages/account/CreateObituary")
);
const EditObituary = React.lazy(() =>
  import("./pages/obituaries/EditObituary")
);
const PlaqueSelection = React.lazy(() =>
  import("./pages/account/PlaqueSelection")
);
const PlaqueSelectionProduct = React.lazy(() =>
  import("./pages/account/PlaqueSelectionProduct")
);
const TributeSubscription = React.lazy(() =>
  import("./pages/account/TributeSubscription")
);
const TributeSubscriptionRedirect = React.lazy(() =>
  import("./pages/account/TributeSubscriptionRedirect")
);

const Cart = React.lazy(() => import("./pages/account/Cart"));
// may combine into single page
const CartSuccess = React.lazy(() =>
  import("./pages/account/CartCheckoutSuccess")
);

const Shop = React.lazy(() => import("./pages/shop/Shop"));
const ShopProduct = React.lazy(() => import("./pages/shop/ShopProduct"));

const Profile = React.lazy(() => import("./pages/account/Profile"));
const PaySetupRedirect = React.lazy(() =>
  import("./pages/account/PaySetupRedirect")
);
const UserUploads = React.lazy(() => import("./pages/account/UserUploads"));
const Order = React.lazy(() => import("./pages/account/Order"));
const OrderList = React.lazy(() => import("./pages/account/Orders"));

const PartnerList = React.lazy(() => import("./pages/partner/Partners"));
const PartnerDashboard = React.lazy(() => import("./pages/partner/Dashboard"));
const PartnerLocationDashboard = React.lazy(() =>
  import("./pages/partner/LocationDashboard")
);
const PartnerSignUp = React.lazy(() => import("./pages/partner/PartnerSignUp"));

const LocationLandingPage = React.lazy(() =>
  import("./pages/partner/LocationLandingPage")
);
const LocationPublicMapPage = React.lazy(() =>
  import("./pages/partner/LocationPublicMapPage")
);

const LocationMemorialPage = React.lazy(() =>
  import("./pages/partner/LocationMemorialPage")
);

const AdminObituaries = React.lazy(() =>
  import("./pages/admin/AdminObituaries")
);
const AdminOrder = React.lazy(() => import("./pages/admin/Order"));
const AdminOrderList = React.lazy(() => import("./pages/admin/Orders"));
const AdminUserList = React.lazy(() => import("./pages/admin/Users"));

// ----------------------------------------------------------------------

export default function Router() {
  const location = useLocation();
  const { setShowBanner } = useAppState();

  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/services" ||
      location.pathname === "/about" ||
      location.pathname === "/shop" ||
      location.pathname.startsWith("/learn") ||
      location.pathname === "/obituaries" ||
      location.pathname === "/partnersignup" ||
      /^\/obituaries\/\d+$/.test(location.pathname)
    )
      setShowBanner(true);
    else setShowBanner(false);
  }, [location.pathname, setShowBanner]);

  return useRoutes([
    {
      path: "/404",
      element: <NotFound />,
    },
    {
      path: "/invite",
      element: (
        <Suspense fallback={<Spinner fixedPosition={true} />}>
          <AcceptInvite />
        </Suspense>
      ),
    },
    {
      path: "/",
      element: (
        <Suspense fallback={<Spinner fixedPosition={true} />}>
          <PortalLayout />
        </Suspense>
      ),
      children: [
        { index: true, element: <Home /> },

        { path: "verifyNewEmail", element: <VerifyNewEmail /> },
        { path: "privacypolicy", element: <PrivacyPolicy /> },
        { path: "termsandconditions", element: <PrivacyPolicy /> },
        { path: "services", element: <Services /> },
        { path: "about", element: <About /> },
        { path: "landing/:id", element: <Landing /> },
        {
          path: "account",
          element: <AuthenticatedOutlet />,
          children: [
            { path: "profile", element: <Profile /> },
            { path: "PaySetupRedirect", element: <PaySetupRedirect /> },
            { path: "createObituary/:id", element: <CreateObituary /> },
            {
              path: "createObituary/:id/plaqueSelection",
              element: <PlaqueSelection />,
            },
            {
              path: "tribute/:id/subscription",
              element: <TributeSubscription />,
            },
            {
              path: "tribute/:id/subscriptionRedirect",
              element: <TributeSubscriptionRedirect />,
            },
            {
              path: "createObituary/:id/plaqueSelection/:productId",
              element: <PlaqueSelectionProduct />,
            },
            { path: "order", element: <OrderList /> },
            { path: "order/:orderId", element: <Order /> },
            { path: "cart", element: <Cart /> },
            { path: "cart/checkoutSuccess/:orderId", element: <CartSuccess /> },
            { path: "uploads", element: <UserUploads /> },
          ],
        },
        {
          path: "obituaries",
          element: <Outlet />,
          children: [
            { path: "", element: <ObituaryList /> },

            { path: ":obituaryId", element: <Obituary /> },
          ],
        },
        {
          path: "obituaries",
          element: <AuthenticatedOutlet />,
          children: [
            {
              path: ":obituaryId/edit",
              element: <EditObituary />,
            },
            {
              path: ":obituaryId/PlaqueSelection",
              element: <PlaqueSelection />,
            },
          ],
        },
        {
          path: "shop",
          element: <Outlet />,
          children: [
            { path: "", element: <Shop /> },
            { path: ":productId", element: <ShopProduct /> },
          ],
        },
        {
          path: "learn",
          element: <Outlet />,
          children: [
            { path: "", element: <UserArticleList /> },
            { path: ":topicSlug/:articleSlug", element: <UserArticle /> },
            { path: ":topicSlug", element: <TopicArticleList /> },
          ],
        },
        {
          path: "partner",
          element: <AuthenticatedOutlet />,
          children: [
            { path: "", element: <PartnerList /> },
            { path: ":partnerId", element: <PartnerDashboard /> },
            {
              path: ":partnerId/location/:locationId",
              element: <PartnerLocationDashboard />,
            },
          ],
        },
        {
          path: "partnersignup",
          element: <Outlet />,
          children: [{ path: "", element: <PartnerSignUp /> }],
        },
        {
          path: "p/:locationSlug",
          children: [
            { path: "", element: <LocationLandingPage /> },
            {
              path: "map",
              children: [
                { path: "", element: <LocationPublicMapPage /> },
                { path: ":memorialId", element: <LocationMemorialPage /> },
              ],
            },
          ],
        },
        {
          path: "admin",
          element: <AuthenticatedOutlet />,
          children: [
            { path: "obituaries", element: <AdminObituaries /> },
            { path: "users", element: <AdminUserList /> },
            {
              path: "articles",

              children: [
                { path: "", element: <AdminArticleList /> },
                { path: ":articleSlug", element: <AdminArticle /> },
                {
                  path: ":articleSlug/edit",
                  element: <EditArticle />,
                },
              ],
            },
            {
              path: "topics",

              children: [
                { path: "", element: <TopicList /> },
                { path: ":topicId", element: <Topic /> },
                {
                  path: ":topicId/edit",
                  element: <EditTopic />,
                },
              ],
            },
            {
              path: "orders",

              children: [
                { path: "", element: <AdminOrderList /> },
                { path: ":orderId", element: <AdminOrder /> },
              ],
            },
            {
              path: "product",
              children: [
                { path: "", element: <ProductList /> },
                {
                  path: ":id",
                  element: <Product />,
                },
                {
                  path: ":id/edit",
                  element: <EditProduct />,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <AuthenticationLayout />,
      children: [
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "verify", element: <Verify /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "passwordReset", element: <PassswordReset /> },
      ],
    },
    { path: "*", element: <Redirector /> },
  ]);
}

import React, { useState } from "react";
import {
  useObituary,
  useUpdateObituary,
  useUpdateObituaryStatus,
  useAddObituaryDraft,
} from "src/hooks/obituary";
import { useSnackbar } from "notistack";
import { trackSaveObituary, trackCreateObituary } from "src/utils/trackingTags";
import { Dialog, DialogTitle, DialogContent } from "@mui/material";

import { Step1 } from "./Step1.js";
import { Step2 } from "./Step2.js";
import { Step3 } from "./Step3.js";
import { Step4 } from "./Step4.js";
import { Step5 } from "./Step5.js";
import { Step6 } from "./Step6.js";
import { Step7 } from "./Step7.js";

const CreateObituaryWizard = ({ draftId, memorialData = null, onClose }) => {
  const [step, setStep] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [tributeId, setTributeId] = useState(draftId);

  const obituaryResponse = useObituary(
    tributeId,
    true,
    true,
    !tributeId ? false : true
  );

  draftId && (memorialData = null);
  const {
    displayName = "",
    firstName = "",
    lastName = "",
    middleName = "",
  } = memorialData || {};

  const subscriptionLevel = obituaryResponse.data?.subscriptionLevel;

  const tribute = obituaryResponse.data?.obituary || {
    displayName: displayName ? displayName : "",
    firstName: firstName ? firstName : "",
    lastName: lastName ? lastName : "",
    middleName: middleName ? middleName : "",
    isFullDateOfBirth: true,
    isFullDateOfDeath: true,
  };

  const handleNext = () => setStep(step + 1);
  const handlePrevious = () => setStep(step - 1);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const { enqueueSnackbar } = useSnackbar();
  const updateObituary = useUpdateObituary();
  const updateObituaryStatus = useUpdateObituaryStatus();
  const addObituaryDraft = useAddObituaryDraft();

  const handleCreate = async (values) => {
    try {
      if (tributeId === undefined || tributeId === null) {
        addObituaryDraft.mutate(
          {
            firstName: values.firstName,
            lastName: values.lastName,
            middleName: values.middleName,
            displayName: values.displayName,
            ...(memorialData && {
              obituaryMemorial: { ...memorialData.obituaryMemorial },
            }),
          },
          {
            onSuccess: (draft) => {
              setTributeId(draft.id);
              trackCreateObituary(draft.id);
              enqueueSnackbar("Draft Created Successfully", {
                variant: "success",
              });
              setStep(step + 1);
            },
          }
        );
      } else {
        handleSave(values);
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const handleSave = async (values) => {
    try {
      if (step === 5) {
        await updateObituaryStatus.mutateAsync({
          id: tributeId,
          status: "published",
        });
      }
      await updateObituary.mutateAsync({
        obituaryId: tributeId,
        obituary: {
          ...values,
          dateOfBirth: values.dateOfBirth ? values.dateOfBirth : undefined,
          dateOfDeath: values.dateOfDeath ? values.dateOfDeath : undefined,
        },
      });

      trackSaveObituary(tributeId); // google tag event
      enqueueSnackbar("Saved Successfully", { variant: "success" });
      setStep(step + 1);
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  };

  const renderStep = () => {
    if (obituaryResponse.isLoading || obituaryResponse.isFetching) {
      return <></>;
    }
    switch (step) {
      case 1:
        return <Step1 tribute={tribute} onNext={handleCreate} />;
      case 2:
        return (
          <Step2
            tributeId={tributeId}
            tribute={tribute}
            onNext={handleNext}
            onBack={handlePrevious}
          />
        );
      case 3:
        return (
          <Step3
            tribute={tribute}
            onNext={handleSave}
            onBack={handlePrevious}
          />
        );
      case 4:
        return (
          <Step4
            tribute={tribute}
            onNext={handleSave}
            onBack={handlePrevious}
          />
        );
      case 5:
        return (
          <Step5
            tribute={tribute}
            allowedWordCount={subscriptionLevel?.allowedWordCount || 250}
            onNext={handleSave}
            onBack={handlePrevious}
          />
        );
      case 6:
        return (
          <Step6 id={tributeId} close={handleCloseModal} onNext={handleNext} />
        );
      case 7:
        return <Step7 id={tributeId} close={handleCloseModal} />;
      default:
        return null;
    }
  };

  return (
    <Dialog open={isModalOpen} onClose={handleCloseModal} fullWidth>
      <DialogTitle sx={{ textAlign: step >= 6 ? "center" : "left" }}>
        {step < 6
          ? "Create Free Tribute"
          : "Your Loved One's Free Tribute is Ready!"}
      </DialogTitle>
      <DialogContent>{renderStep()}</DialogContent>
    </Dialog>
  );
};

export default CreateObituaryWizard;

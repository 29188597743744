export function updateUserIdInDataLayer(userId) {
  window.dataLayer.push({
    user_id: userId,
  });
}

export function trackLogin(userId, method = "website_default") {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "login",
      method: method,
    },
    user_id: userId,
  });
}

export function trackCreateObituary(obituaryId) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "create_obituary",
      obituary_id: obituaryId,
    },
  });
}

export function trackSaveObituary(obituaryId) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "save_obituary",
      obituary_id: obituaryId,
    },
  });
}

export function trackUploadObituaryPhoto(obituaryId, primaryPhoto) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "upload_obituary_photo",
      obituary_id: obituaryId,
      primary_photo: primaryPhoto,
    },
  });
}

export function trackSignUp(userId, method = "website_default") {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "sign_up",
      method: method,
    },
    user_id: userId,
  });
}

export function trackViewItemList(
  item_list_id,
  item_list_name,
  productEntries
) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "view_item_list",
      item_list_id: item_list_id,
      item_list_name: item_list_name,
      items: productEntries.map((x) => mapProductEntryToItem(x)),
    },
  });
}

export function trackViewItem(productEntry) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "view_item",
      value: productEntry.priceUnitAmount,
      items: [mapProductEntryToItem(productEntry)],
    },
  });
}

export function trackAddToCart(productEntry, addedQuantity = 1) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "add_to_cart",
      value: productEntry.priceUnitAmount * addedQuantity,
      items: [mapProductEntryToItem(productEntry, addedQuantity)],
    },
  });
}

export function trackRemoveFromCart(productEntry, removedQuantity = 1) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "remove_from_cart",
      value: productEntry.priceUnitAmount * removedQuantity,
      items: [mapProductEntryToItem(productEntry, removedQuantity)],
    },
  });
}

export function trackViewCart(cartLines) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "view_cart",
      value: getTotalFromCartLines(cartLines),
      items: getItemsFromCartLines(cartLines),
    },
  });
}

export function trackAddShippingInfo(cartLines) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "add_shipping_info",
      value: getTotalFromCartLines(cartLines),
      items: getItemsFromCartLines(cartLines),
    },
  });
}

export function trackBeginCheckout(cartLines) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "begin_checkout",
      value: getTotalFromCartLines(cartLines),
      items: getItemsFromCartLines(cartLines),
    },
  });
}

export function trackPurchase(orderId, cartLines) {
  window.dataLayer.push({
    event: "genericEvent",
    eventProps: {
      eventName: "purchase",
      transaction_id: orderId,
      value: getTotalFromCartLines(cartLines),
      items: getItemsFromCartLines(cartLines),
    },
  });
}

const getTotalFromCartLines = (lines) => {
  const totalValue = (lines || []).reduce(
    (total, line) => total + line.quantity * line.product.priceUnitAmount,
    0
  );
  return totalValue;
};

const getItemsFromCartLines = (lines) => {
  return (lines || []).map((x) => mapProductEntryToItem(x.product, x.quantity));
};

const mapProductEntryToItem = (entry, quantity) => {
  return {
    item_id: entry.id,
    item_name: `${entry.name}:${entry.shortDescription}`,
    item_category: entry.category,
    item_category2: entry.includesBasicSubscription
      ? "BasicSubscriptionIncluded"
      : "NoSubscriptionIncluded",
    item_category3: entry.requiresObituaryId
      ? "RequiresObituary"
      : "NoObituaryRequired",
    price: entry.priceUnitAmount,
    quantity: quantity,
  };
};

// const eventProps = {
//   eventName: "",
//   method: "",
//   currency: "USD",
//   transaction_id: "blah",
//   value: 0.0,
//   item_list_id: "",
//   item_list_name: "",
//   obituary_id: 1,
//   primary_photo: false,
//   items: [
//     {
//       item_id: "",
//       item_name: "",
//       item_list_id: "",
//       price: 0.0,
//       quantity: 0,
//     },
//   ],
// };

import React, { createContext, useContext, useState, useCallback } from "react";

const WizardContext = createContext();

export const useWizardContext = () => useContext(WizardContext);

export const WizardProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [wizardOpen, setWizardOpen] = useState(false);
  const [wizardDraftId, setWizardDraftId] = useState(null);
  const [memorialData, setMemorialData] = useState(null);

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setWizardOpen(false);
    setWizardDraftId(null);
    setMemorialData(null);
  }, []);

  const openModal = useCallback((data) => {
    setMemorialData(data);
    setModalOpen(true);
    setWizardOpen(false);
  }, []);

  const openWizard = useCallback((draftId) => {
    setWizardOpen(true);
    setWizardDraftId(draftId);
  }, []);

  return (
    <WizardContext.Provider
      value={{
        modalOpen,
        wizardOpen,
        wizardDraftId,
        memorialData,
        setWizardDraftId,
        openModal,
        openWizard,
        closeModal,
      }}
    >
      {children}
    </WizardContext.Provider>
  );
};

import { TextField, MenuItem, InputAdornment } from "@mui/material";
import { Controller } from "react-hook-form";

// ----------------------------------------------------------------------

export default function HookFormTextField({
  name,
  label,
  helperText,
  formMethods,
  onChange: onChangeHandler = () => {},
  deps = [],
  options = [],
  children,
  select = false,
  currency = false, // Add currency prop
  ...props
}) {
  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <TextField
          id={name}
          name={name}
          label={label || name}
          helperText={errors[name]?.message || helperText || ""}
          error={errors[name] && Boolean(errors[name])}
          value={
            currency
              ? (value || "").toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })
              : value || ""
          }
          onChange={(event) => {
            deps.forEach((x) => trigger(x));
            onChange(event);
            onChangeHandler(event);
          }}
          select={select}
          InputProps={
            currency
              ? {
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }
              : {}
          }
          {...props}
        >
          {select
            ? options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            : children}
        </TextField>
      )}
    />
  );
}

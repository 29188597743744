import styled from "@emotion/styled";
import useIsInViewport from "src/hooks/useIsInViewport";
import palette from "../theme/palette";

const StyledAnimatedHeaderUnderline = styled.div`
  width: ${(props) => (props.transitioning ? "100%" : 0)};
  margin: 0 auto;
  height: 0.2rem;
  background-color: ${(props) => props.backgroundColor};
  border-radius: 4px;
  transition: width 0.5s ease-out;
`;

export default function AnimatedHeaderUnderline({
  backgroundColor = palette.primary.light,
}) {
  const { elementRef, isInViewport } = useIsInViewport(true);
  
  return (
    <StyledAnimatedHeaderUnderline
      ref={elementRef}
      transitioning={isInViewport}
      backgroundColor={backgroundColor}
    />
  )
}

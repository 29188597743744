import { Box, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { SupportsWebp } from "src/slices/appSlice";

const Hero = ({
  headline,
  subtext,
  imageWithoutExtension = "/static/homepage/hero",
  buttonStack,
  locationButton,
  isLocation = false,
  logoImageSrc,
  logoImage,
  editBasicInfo,
  editHeroButton,
  editLogoButton,
}) => {
  const webpSupport = useSelector(SupportsWebp);

  const renderSubtext = () => {
    if (isLocation) {
      return subtext.split("\n").map((line, index) => (
        <Typography key={index} sx={{ display: "block" }} variant="heroSubtext">
          {line}
          <br />
        </Typography>
      ));
    } else {
      return (
        <>
          <Typography sx={{ display: "block" }} variant="heroSubtext">
            {subtext}
          </Typography>
          <br />
        </>
      );
    }
  };

  return (
    <Stack
      direction="column"
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("${imageWithoutExtension}.${
          webpSupport ? "webp" : "jpg"
        }")`,
      }}
    >
      {logoImageSrc ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            textAlign: "center",
            paddingTop: "1rem",
            borderRadius: "10px",
            overflow: "hidden",
          }}
        >
          {logoImage !== null ? (
            <img
              src={logoImageSrc}
              alt="Location Logo"
              width={95}
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <div
              style={{
                width: 95,
                height: 95,
                borderRadius: "50%",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "auto",
                marginTop: "-0.5rem",
              }}
            >
              <span>Your Logo</span>
            </div>
          )}
          {editLogoButton}
        </Box>
      ) : (
        <Box sx={{ height: { xs: "2rem", md: "4rem", lg: "6rem" } }} />
      )}
      {editHeroButton ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: "1rem",
          }}
        >
          {editHeroButton}
        </Box>
      ) : null}
      <Stack
        spacing={0}
        sx={{
          position: "relative",
          textAlign: "left",
          width: "100%",
          paddingLeft: { xs: "2rem", md: "4rem", lg: "8rem" },
        }}
      >
        <Typography
          variant="hero"
          variantMapping={{ hero: "h1" }}
          sx={{
            minHeight: "120px",
          }}
        >
          {headline}
        </Typography>
        {renderSubtext()}
        <Box
          sx={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
        >
          {editBasicInfo}
        </Box>
        {buttonStack}
      </Stack>
      {isLocation ? (
        <Box width={"100%"} textAlign={"center"} sx={{ padding: "2rem" }}>
          {locationButton}
        </Box>
      ) : (
        <Box sx={{ height: { xs: "2rem", md: "4rem", lg: "6rem" } }} />
      )}
    </Stack>
  );
};

export default Hero;

import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ImgWithFallback from "src/components/ImgWithFallback";

const IconWithDescription = ({
  iconUrl,
  title,
  description,
  buttonText,
  to,
  buttonComponent,
  imageWithoutExtension,
}) => {
  return (
    <Box
      sx={{
        marginTop: "0 !important",
        display: "inline-flex",
        flexDirection: "column",
        padding: 4,
        flexGrow: 1,
        flexBasis: 0,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "inline-flex",
            flexDirection: "row",
            alignItems: "center",
            "@media (max-width: 900px)": {
              display: "flex",
              justifyContent: "center",
            },
          }}
        >
          <img src={iconUrl} width="65" alt={title} />
          <Typography
            sx={{
              marginLeft: 2,
            }}
            variant="h3"
          >
            {title}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: 2 }}>
        {description}
        <Box
          sx={{
            display: "block",
            width: "max-content",
            marginTop: 2,
            "@media (max-width: 900px)": {
              marginLeft: "auto",
              marginRight: "auto",
            },
          }}
        >
          {to && buttonText && (
            <Button variant="outlined" to={to} component={Link}>
              {buttonText}
            </Button>
          )}
          {buttonComponent}
        </Box>
        {imageWithoutExtension && (
          <Box sx={{ maxWidth: "500px", margin: "auto" }}>
            <ImgWithFallback
              src={`${imageWithoutExtension}.webp`}
              fallbackSrc={`${imageWithoutExtension}.png`}
              alt={title}
              style={{
                margin: "2rem auto 0 auto",
                display: "block",
                maxHeight: "350px",
                objectFit: "cover",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default IconWithDescription;

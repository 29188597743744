import config from "./config";
// ----------------------------------------------------------------------
export function resolvePrimaryImageSrc(
  namespace,
  objectId,
  primaryImageFileNamePrefix,
  size,
  type
) {
  return `${config.contentUrl}/${namespace}/${objectId}/primary/${primaryImageFileNamePrefix}_${size}.${type}`;
}

export function resolvePartnerLocationImageSrc(
  namespace,
  objectId,
  primaryImageFileNamePrefix,
  size,
  type
) {
  return `${config.contentUrl}/partnerlocation/${objectId.locationId}/${primaryImageFileNamePrefix}_${size}.${type}`;
}

export function resolvePartnerLocationHeroImageSrc(
  locationId,
  primaryImageFileNamePrefix
) {
  return `${config.contentUrl}/partnerlocation/${locationId}/${primaryImageFileNamePrefix}_lg`;
}

export function resolvePartnerLocationLogoImageSrc(
  locationId,
  primaryImageFileNamePrefix
) {
  return `${config.contentUrl}/partnerlocation/${locationId}/${primaryImageFileNamePrefix}_md.jpg`;
}

export function resolveGalleryImageSrc(
  namespace,
  objectId,
  imageFileNamePrefix,
  size,
  type
) {
  return `${config.contentUrl}/${namespace}/${objectId}/gallery/${imageFileNamePrefix}_${size}.${type}`;
}

export function getImageObject(
  namespace,
  objectId,
  imageFileNamePrefix,
  isPrimary
) {
  const url = `${config.contentUrl}/${namespace}/${objectId}/${
    isPrimary ? "primary" : "gallery"
  }/${imageFileNamePrefix}_`;
  return {
    webp: {
      sm: `${url}sm.webp`,
      md: `${url}md.webp`,
      lg: `${url}lg.webp`,
    },
    jpg: {
      sm: `${url}sm.jpg`,
      md: `${url}md.jpg`,
      lg: `${url}lg.jpg`,
    },
    prefix: imageFileNamePrefix,
  };
}

export function primaryObituaryImageLink(
  obituaryId,
  primaryImageFileNamePrefix,
  size,
  type
) {
  return resolvePrimaryImageSrc(
    "obituary",
    obituaryId,
    primaryImageFileNamePrefix,
    size,
    type
  );
}

export function obituaryImageLink(obituaryId, imageFileNamePrefix, size, type) {
  return resolveGalleryImageSrc(
    "obituary",
    obituaryId,
    imageFileNamePrefix,
    size,
    type
  );
}

export function getObituaryImageObject(
  obituaryId,
  imageFileNamePrefix,
  isPrimary
) {
  const url = `${config.contentUrl}/obituary/${obituaryId}/${
    isPrimary ? "primary" : "gallery"
  }/${imageFileNamePrefix}_`;
  return {
    webp: {
      sm: `${url}sm.webp`,
      md: `${url}md.webp`,
      lg: `${url}lg.webp`,
    },
    jpg: {
      sm: `${url}sm.jpg`,
      md: `${url}md.jpg`,
      lg: `${url}lg.jpg`,
    },
    prefix: imageFileNamePrefix,
  };
}

import { Button } from "@mui/material";
import { useWizardContext } from "src/features/CreateTributeWizard/WizardContext";

const CreateObituaryButton = ({ memorialData = null, children, ...props }) => {
  const { openModal, openWizard } = useWizardContext();

  return (
    <Button
      id="landing-register-button"
      {...props}
      onClick={() => {
        if (!memorialData) {
          openModal();
        } else {
          openModal(memorialData);
          openWizard(null);
        }
      }}
    >
      {children}
    </Button>
  );
};

export default CreateObituaryButton;
